// import React, { useState } from "react";
// import { Link as Link2 } from "react-router-dom";

import BackgroundVideo from "../assets/videos/video8.mp4";

// import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import { useTranslation } from "react-i18next";

export default function About() {
  // const [isOpen, setOpen] = useState(false)
  const { t } = useTranslation();

  return (
    <>
<section
  className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
  id="about"
>
  <div className="container mx-auto">
    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
      <div className="lg:col-span-5 h-full">
        <div className="relative h-full">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="rounded-lg shadow-lg w-full h-full object-cover"
          >
            <source src={BackgroundVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      {/* end col */}

      <div className="lg:col-span-7">
        <div className="lg:ms-7">
          <h6 className="text-orange-600 text-base font-medium uppercase mb-2">
            {t("About.aboutUs")}
          </h6>
          <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
            {t("About.whoWeAre")}
          </h3>

          <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
            {t("About.whoWeAreContentP1")}
          </p>
          <br />
          <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
            {t("About.whoWeAreContentP2")}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

      {/* <!-- End --> */}
      {/* <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="yba7hPeTSjk"
        onClose={() => setOpen(false)}
      /> */}
    </>
  );
}

import React from "react";
import { Link } from "react-scroll";

import Typed from "typed.js";
import BackgroundVideo from "../assets/videos/video3.mp4";
import Services from "../component/Services";
import About from "../component/About";
import Pricing from "../component/Pricing";
import Blog from "../component/Blog";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Portfolio";
import Review from "../component/Testimonial";
// import Switcher from "../component/Switcher";
import Navbar from "../component/navbar";
import { useTranslation } from "react-i18next";
import Supporters from "../component/Supporters";

/**
 * Index Component
 */
export default function Index() {
  const { t } = useTranslation();
  const portfolioVisible = false;
  const reviewVisible = false;
  const pricingVisible = false;
  const blogVisible = false;

  setTimeout(() => {
    new Typed("#typed", {
      strings: [
        "<b>" + t("Index.business") + "</b>",
        "<b>" + t("Index.startup") + "</b>",
      ],
      backDelay: 2000,
      loop: true,
      startDelay: 300,
      typeSpeed: 50,
      backSpeed: 50,
    });
  }, 500);

  return (
    <>
      <div>
        <Navbar
          portfolioVisible={portfolioVisible}
          reviewVisible={reviewVisible}
          pricingVisible={pricingVisible}
          blogVisible={blogVisible}
        />
<section
  className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
  id="home"
>
  {/* Background Video */}
  <video
    autoPlay
    loop
    muted
    playsInline
    className="absolute inset-0 w-full h-full object-cover"
  >
    <source src={BackgroundVideo} type="video/mp4" />
    Your browser does not support the video tag.
  </video>

  {/* Overlay */}
  <div className="absolute inset-0 bg-black opacity-80"></div>

  <div className="container relative">
    <div className="grid grid-cols-1 mt-12">
      <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
        {t("Index.title")} <br />
        {t("Index.your")}{" "}
        <span
          className="typewrite relative text-type-element"
          id="typed"
          data-period="2000"
          data-type='[ "Business", "Startups", "Digital Agency", "Marketing" ]'
        ></span>
      </h4>

      <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
        {t("Index.subtext")}
      </p>

      <div className="relative mt-10">
        <Link
          to="contact"
          className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md"
        >
          {t("Index.getStarted")}
        </Link>
      </div>
    </div>
  </div>

</section>
    {/* Attribution */}
    <div className="text-center mt-2" style= {{color :'white'}}>
    <a href="https://www.vecteezy.com/free-videos/sphere">Sphere Stock Videos by Vecteezy</a>
  </div>

        {/* About section */}
        <Services />
        <About />
        {/* Service section */}
        
        {/* Portfolio section */}
        {portfolioVisible && <Portfolio />}
        {/* Review section */}
        {false && <Review />}
        {/* Pricing section */}
        {pricingVisible && <Pricing />}
        {/* Blog section */}
        {blogVisible && <Blog />}
        {/* Contact section */}
        <Contact />
        <Supporters />
        {/* Footer section */}
        <Footer />
        {/* Switcher section */}
        {/* <Switcher /> */}
      </div>
    </>
  );
}
